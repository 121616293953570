import $ from 'jquery';
import 'slick-carousel';

// 注目の企業のスライダー
window.addEventListener('load', () => {
  $('.slick__companies').slick({
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 5000,
    slidesToShow: 3,
    variableWidth: true,
    // centerMode: true,
    dots: true,
    prevArrow: "<button class='prev'><img src='/images/arrow__left.png' class='slideArrow slideArrow__prev'></button>",
    nextArrow: "<button class='next'><img src='/images/arrow__right.png' class='slideArrow slideArrow__next'></button>"
  });
});
window.addEventListener('load', () => {
  $('.slick__companies--sp').slick({
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 5000,
    slidesToShow: 1,
    variableWidth: true,
    centerMode: true,
    prevArrow: "<button class='prev'><img src='/images/arrow__left.png' class='slideArrow slideArrow__prev'></button>",
    nextArrow: "<button class='next'><img src='/images/arrow__right.png' class='slideArrow slideArrow__next'></button>"
  });
});
